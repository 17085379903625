import { initializeApp } from 'firebase/app';
import {
  FirebaseStorage,
  getStorage,
  ref,
  uploadBytesResumable,
} from 'firebase/storage';
import { firebaseApiKey } from '@/utils/constants';
import { knapsackGlobal } from './global';

const firebaseConfig = {
  apiKey: firebaseApiKey,
  authDomain: 'knapsack-cloud.firebaseapp.com',
  // databaseURL: `https://knapsack-cloud.firebaseio.com`,
  projectId: 'knapsack-cloud',
  storageBucket: 'knapsack-cloud.appspot.com',
  messagingSenderId: '768120331781',
  appId: '1:768120331781:web:c22d88a1c7eb46eb15c66c',
  measurementId: 'G-RM83PVHYF5',
};

export const firebaseApp = initializeApp(firebaseConfig);
export const getKSstorage = (): FirebaseStorage => {
  const fbStorage = getStorage(firebaseApp);

  // Grabbing feature flags direct from Global to avoid circular dependencies from useAppCtxSelector
  const { enableFirebaseStorageURLOverride } =
    knapsackGlobal.appService.state.context.featureFlags;
  // StorageEmulator hard codes http. Allowing https is under consideration but I suspect
  // not forthcoming to prevent doing exactly what we're doing for business reasons.
  // connectStorageEmulator(storage, 'storage.knapsack.cloud', 443);
  if (enableFirebaseStorageURLOverride) {
    (fbStorage as any)._host = 'storage.knapsack.cloud';
  }
  return fbStorage;
};

export type UserErrorCodes =
  | 'auth/wrong-password'
  | 'auth/user-not-found'
  | 'auth/user-disabled'
  | 'auth/invalid-email'
  | 'auth/too-many-requests';

/**
 * Event emitter that handles much of the firebase storage setup.
 * @example
 * fbUploadTask({url: 'images/pic.jpg, data, meta}).on(
 *   'state_changed',
 *   () => // handleChange,
 *   () => // handleError,
 *   () => // handleSuccess,
 * )
 * @see
 * https://firebase.google.com/docs/storage/web/upload-files
 * @see
 * image-upload.ts
 */
export const fbUploadTask = ({
  url,
  data,
  meta,
}: {
  url: Parameters<typeof ref>[1];
  data: Parameters<typeof uploadBytesResumable>[1];
  meta?: Parameters<typeof uploadBytesResumable>[2];
}) => uploadBytesResumable(ref(getKSstorage(), url), data, meta);
