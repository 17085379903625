/**
 * Acesses files inside the `images/` folder in the Firebase Storage bucket
 * @link https://console.firebase.google.com/u/1/project/knapsack-cloud/storage/knapsack-cloud.appspot.com/files
 * @link https://dashboard.imgix.com/sources/5f8877fe715a3d8e523c1f3c
 * gs://knnapsack-cloud.appspot.com
 */
export const imgixBaseUrl = 'https://knapsack.imgix.net';
export const awsS3BaseUrl = 'https://files.knapsack.cloud';

export function isValidUrl(url: string): boolean {
  const urlToCheck = new URL(url);
  return (
    urlToCheck.origin === imgixBaseUrl || urlToCheck.origin === awsS3BaseUrl
  );
}

function validateUrl(url: string) {
  if (!isValidUrl(url)) {
    throw new Error(
      `Can only create image urls if they start with "${imgixBaseUrl}" or "${awsS3BaseUrl}", this was used: "${url}"`,
    );
  }
}

/**
 * @see https://docs.imgix.com/apis/rendering
 * */
export function createResizedImageUrl({
  url,
  width,
}: {
  url: string;
  width: number;
}): string {
  // exit early if rendering an instant preview of image being uploaded. Fixes https://linear.app/knapsack/issue/KSP-2644/adding-image-to-image-block-breaks
  if (url.startsWith('blob:http')) return url;

  validateUrl(url);
  if (url.endsWith('svg')) return url;
  // don't try to auto-resize gifs (imgix limitation afaik)
  if (url.endsWith('gif')) return url;
  const imgUrl = new URL(url);
  imgUrl.searchParams.set('w', width.toString());
  imgUrl.searchParams.set('auto', 'format');
  return imgUrl.toString();
}

/**
 * @see https://docs.imgix.com/apis/rendering/size/fit#facearea
 * */
export function createFaceCroppedImageUrl({
  url,
  width,
  height,
}: {
  url: string;
  width: number;
  height: number;
}): string {
  validateUrl(url);
  const imgUrl = new URL(url);
  imgUrl.searchParams.set('fit', 'facearea');
  imgUrl.searchParams.set('w', width.toString());
  imgUrl.searchParams.set('h', height.toString());
  imgUrl.searchParams.set('auto', 'compress');
  imgUrl.searchParams.set('facepad', '4');
  return imgUrl.toString();
}

/**
 * @see https://docs.imgix.com/apis/rendering
 * */
export function createCustomImageUrl({
  url,
  imageParams,
}: {
  url: string;
  /**
   * Use any of the huge range of possibilites from the [imgIX Rendering API](https://docs.imgix.com/apis/rendering)
   */
  imageParams?: Record<string, string>;
}): string {
  validateUrl(url);
  const imgUrl = new URL(url);
  if (imageParams) {
    Object.entries(imageParams).forEach(([key, value]) => {
      imgUrl.searchParams.set(key, value);
    });
  }
  return imgUrl.toString();
}
